import { GetStaticPaths, GetStaticProps } from "next";
import { useRouter } from "next/router";
import Layout from "../components/ui/Layout/Layout";
import DynamicComponent from "../components/DynamicComponent";
import Storyblok, { useStoryblok } from "../utils/storyblok";
import { isDev } from "constants/env";
import { StoryParams } from "storyblok-js-client";
import { TranslatedSlugDef } from "types/translation.type";
import { getFullSlug } from "utils/router.helper";
import { useTranslations } from "context/TranslationContext";
import LoadingScreen from "@molteo-ui/LoadingScreen/LoadingScreen";
import { LinkDef } from "types/links.type";
import { useMount } from "react-use";

const DEFAULT_ROOT_SLUG = "";

export default function Page({
  story,
  preview,
  locale,
  translations,
  settings,
}) {
  useTranslations(translations);
  const { isFallback } = useRouter();

  // use the preview variable to enable the bridge only in preview mode
  const liveStory = useStoryblok(story, preview, locale);
  const hasLiveStory = typeof liveStory?.content !== "undefined";

  if (preview && hasLiveStory) {
    story = liveStory;
  }

  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running
  if (isFallback || !story) {
    return <LoadingScreen />;
  }

  // geoData.unsubscribed = false;

  // if (window.analytics && geoData) {
  //   window.analytics.identify(geoData.ip, geoData)
  // }

  // get default slug and translated slugs from story
  const translatedSlugs: TranslatedSlugDef[] = [
    {
      path: getFullSlug(story.default_full_slug || story.full_slug),
      lang: "de",
    },
    ...story.translated_slugs?.map(
      (translatedSlug) =>
        ({
          path: getFullSlug(translatedSlug.path),
          lang: translatedSlug.lang,
        } as TranslatedSlugDef)
    ),
  ];

  return (
    <Layout
      locale={locale}
      seoObj={story.content.metadata}
      settings={settings}
      translatedSlugs={translatedSlugs}
    >
      <DynamicComponent
        blok={story.content}
        locale={locale}
        translations={translations}
        settings={settings}
      />
    </Layout>
  );
}

export const getStaticProps: GetStaticProps = async ({
  locale,
  params,
  preview = false,
}) => {
  try {
    let slug = params.slug
      ? Array.isArray(params.slug)
        ? params.slug.join("/")
        : params.slug
      : "";
    const sbParams: StoryParams = {
      version: "published",
      language: locale,
      resolve_links: "url",
    };

    if (isDev() || preview) {
      sbParams.version = "draft";
      sbParams.cv = Date.now();
    }

    if (slug && typeof slug === "string") {
      if (!slug.includes(DEFAULT_ROOT_SLUG)) {
        slug = `${DEFAULT_ROOT_SLUG}/${slug}`;
      }
    } else {
      slug = 'home';
    }

    // Fetch Global settings
    const settings = await Storyblok.get(`cdn/stories/settings`, sbParams);
    // Fetch Static Language Labels
    const translationsTexts = await Storyblok.get(
      `cdn/datasource_entries?datasource=public-company-labels`,
      { dimension: locale, per_page: 1000 }
    );

    // Fetch page content and resolve links as stories 
    const { data } = await Storyblok.get(`cdn/stories/${slug}`, {
      ...sbParams,
      resolve_links: "story",
    });

    return data
      ? {
          props: {
            story: data.story,
            preview,
            locale,
            translations: translationsTexts?.data?.datasource_entries || false,
            settings: settings?.data?.story?.content || false,
          },
          revalidate: 60, // revalidate every minute
        }
      : { notFound: true };
  } catch (err) {
    console.error("[storyblok]", err.response?.data);
    return { notFound: true };
  }
};

export const getStaticPaths: GetStaticPaths = async () => {
  const DEFAULT_ROOT_PAGE = ""; // important it ends with slash
  const { data } = await Storyblok.get("cdn/links/", {
    version: isDev() ? "draft" : "published",
    starts_with: DEFAULT_ROOT_PAGE,
  } as StoryParams);

  const paths = [];
  Object.values(data.links).forEach((link: LinkDef) => {
    if(
      link.slug.includes("/land") ||
      link.slug.includes("poliergpt")
    ) {
      return
    }
    const slug = link.slug.replace(DEFAULT_ROOT_PAGE, "");
    let splitSlug: string[] | false = slug.split("/");

    if (slug === "/" || slug === "" || slug === 'home') {
      splitSlug = false;
    }

    paths.push({
      params: { slug: splitSlug },
      locale: "de",
    });

    link.alternates?.forEach((alternate) => {
      let altSplitSlug: string[] | false = alternate.path.split("/");
      if(alternate.path.includes("/land")) {
        return
      }
      if (alternate.path === "/" || alternate.path === "") {
        altSplitSlug = false;
      }
      paths.push({ params: { slug: altSplitSlug }, locale: alternate.lang });
    });
  });

  return {
    paths,
    fallback: false,
  };
};
