import dynamic from "next/dynamic";
import { useWindowSize } from "react-use";

const ContentLoader = dynamic(() => import("react-content-loader"), {
  ssr: false,
});

const LoadingScreen = () => {
  const { width } = useWindowSize();
  return (
    <div className="bg-gray-900 h-full min-h-screen flex flex-col justify-center items-center">
      <ContentLoader
        speed={1}
        width={width > 1000 ? 600 : 300}
        viewBox="0 0 446 80"
        backgroundColor="#00BCD4"
        foregroundColor="#fff"
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M112.548 16.6464L101.405 42.1852H93.7099L82.5654 16.6464V46.8299H72.0182V0.331791H84.9534L97.557 27.9912L110.16 0.331791H123.029V46.8299H112.615L112.548 16.6464Z" fill="#00BCD4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M141.405 23.6133C141.405 31.4389 147.442 37.4756 154.539 37.4756C161.703 37.4756 167.408 31.4389 167.408 23.6133C167.408 15.7877 161.703 9.88116 154.539 9.88116C147.375 9.88116 141.405 15.7877 141.405 23.6133ZM154.406 47.3632C140.078 47.3632 129.332 37.1438 129.332 23.5483C129.332 10.0113 140.078 -2.38222e-06 154.406 -2.38222e-06C168.801 -2.38222e-06 179.547 10.0828 179.547 23.5483C179.547 37.0788 168.801 47.3632 154.406 47.3632Z" fill="#00BCD4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M217.294 46.8299H185.784V0.396849H197.591V36.9422H217.294V46.8299Z" fill="#00BCD4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M244.427 46.8299H232.613V9.88119H218.751V0.331791H258.419V9.88119H244.427V46.8299Z" fill="#00BCD4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M275.267 18.9037H297.423V28.1864H275.267V37.5407H300.546V46.8299H263.46V0.396849H299.81V9.67955H275.267V18.9037Z" fill="#00BCD4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M317.192 23.6133C317.192 31.4389 323.229 37.4756 330.326 37.4756C337.494 37.4756 343.199 31.4389 343.199 23.6133C343.199 15.7877 337.494 9.88116 330.326 9.88116C323.164 9.88116 317.192 15.7877 317.192 23.6133ZM330.196 47.3632C315.865 47.3632 305.119 37.1438 305.119 23.5483C305.119 10.0113 315.865 -2.38222e-06 330.196 -2.38222e-06C344.591 -2.38222e-06 355.338 10.0828 355.338 23.5483C355.338 37.0788 344.591 47.3632 330.196 47.3632Z" fill="#00BCD4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M61.7217 46.3632H48.2113V19.3317L19.2984 46.3632H0L48.2113 0H61.7217V46.3632Z" fill="#0097A7" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0V46.3632H13.5104V19.3317L42.4233 46.3632H61.7217L13.5113 0H0Z" fill="#00BCD4" />
      </ContentLoader>
    </div>
  );
};

export default LoadingScreen;
